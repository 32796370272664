
    .row-buttons-container {
        @media screen and (min-width: 768px) {
            margin-top: calc(var(--global-margin) * 1.25);
            .uk-button {
                margin-inline-end: calc(var(--global-margin) * 0.25);
            }

            .uk-button:last-of-type {
                margin-inline-end: 0;
            }
        }

        @media screen and (max-width: 767px) {
            display: block;
            .uk-button {
                display: block;
                margin-inline-end: 0;
            }
        }
    }

