
// scrollbar styles for drop down

.select-2-input.no-selection {
    color: var(--global-muted-color);
}
.select2-dropdown {

    $scrollbar-bg: var(--global-muted-background);
    $scrollbar-color: var(--global-muted-color);
    padding: 0;
    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-bg;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        outline: none;
    }

    &.uk-open {
        border: 1px solid var(--global-primary-background);
    }
}

.inner {
    padding: calc(var(--global-margin) * 0.75);
    max-height: calc(40vh - var(--global-margin));
    position: relative;
    $scrollbar-bg: var(--global-muted-background);
    $scrollbar-color: var(--global-muted-color);
    overflow-y: scroll;

    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-bg;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        outline: none;
    }
}

.dropdown-option-wrapper {
    position: relative;
    background: var(--global-muted-background);


}

.select2-dropdown > * {
    cursor: pointer;
}

.filter-wrapper{
    align-items: center;
}
.select2-filter-input {
    position: relative;
}
.select2-dropdown-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);

    top: -15px;
    inset-inline-start: -15px;
    border-radius: 50%;
    transition: opacity 125ms ease;
    background: var(--global-background);
    border: 1px solid var(--global-primary-background);
    z-index: 2;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }
}

.select2-dropdown-clear {
    margin-inline-start: calc(var(--global-margin) * 0.5);
    transition: opacity 125ms ease;
    &:hover {
        opacity: 0.8;
    }
}



