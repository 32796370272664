
    .uk-input-units-field {
        $inputWidth: calc(var(--global-margin) * 2.5);
        flex: 0 0 $inputWidth;
        width: $inputWidth;
        min-width: $inputWidth;
        text-align: center;
    }

    .unit-control{
        transition: opacity 200ms;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
