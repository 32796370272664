
.select2-item-icon {
    padding: calc(var(--global-margin) * 0.35) calc(var(--global-margin) * 0);
    margin-bottom: calc(var(--global-margin) * 0.5);
    background: var(--global-muted-background);
    border-left: 4px solid var(--global-muted-background);
    transition: padding 150ms ease-out 0ms, border-color 150ms ease-out;

    &.highlight {
        padding-inline-start: calc(var(--global-margin) * 0.3);
        border-left-color: var(--global-primary-background);

        .select2-item-icon__icon {
            margin-inline-end: calc(var(--global-margin) * 0.35);
        }
    }

    &.rtl {
        border-left: none;
        border-right: 4px solid var(--global-muted-background);

        &.highlight {
            border-right-color: var(--global-primary-background);
        }
    }


    &:last-of-type {
  //  border-bottom: none;
  }
}
.select2-item-icon__icon {
  display: flex;
  margin-inline-end: calc(var(--global-margin) * 0.2);
  min-height: calc(var(--global-margin) * 1.5);
    transition: margin 150ms ease-out 90ms;


}


