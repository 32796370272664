

    .input-wrapper {

    }
    // support the "stacked" radio inside a horizontal form
    .input-wrapper-error {
        margin-bottom: calc(var(--global-margin) * 2);
    }

    .uk-form-controls--stacked label {
        width: auto;
        display: block;
        float: none;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .uk-form-controls {
        position: relative;

        .validation-pending-spinner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 5;
        }

    }

    .input-inner-wrapper {
        width: 100%;
        position: relative;


    }

    input {
        // remove firefox autofill yaqiness
        -webkit-appearance: none;
        appearance: none;
        filter: none;
    }

    .uk-form-icon-error {
      pointer-events: auto; // allow tooltip on icon
    }


    .uk-form-error-message.error-absolute-position {
         position: absolute;
         top: 100%;
         left: 0;
         width:100%;
    }

    .checkbox-label {
        cursor: pointer;
        display: inline;
        flex: auto;

        .checkbox-wrapper-inner {
            display: flex;
        }

        .uk-checkbox {
            margin-top: 2px;
            min-width: 16px;
        }
    }
