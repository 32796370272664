


    .admin-layout-wrapper{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .admin-outer-grid {
        flex-direction: row;
        flex: 100%;
    }
    //$global-inverse-color
    .side-menu-container {
        width: 180px;
        padding-inline-end: 0;
    }

    .admin-navbar {
        background: var(--global-background);
    }



    .side-menu {
        width: 100%;
        flex: 100%;

        .uk-nav a:not(.uk-logo) {
            position: relative;
            padding-inline-start: calc(var(--global-margin) * 0.25);
            padding-inline-end: calc(var(--global-margin) * 0.25);

            &.uk-active, &:hover {

                &:before {
                    position: absolute;
                    content: ' ';
                    width: 2px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: var(--global-primary-background);
                }
            }
        }
    }

    .admin-notification-widget {
        .notification-icon {
            position: relative;
            cursor: pointer;

            .notification-badge {
                position: absolute;
                top: -70%;
                right: -50%;
                z-index: 2;
            }
        }
    }
