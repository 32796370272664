

    // specific selector to override UIkit
    .color-select-wrapper.uk-form-large:not(textarea):not([multiple]):not([size]) {
        padding-left: 0;
        padding-right: 0;
    }

    .color-list {
        box-sizing: border-box;



        * {
            box-sizing: border-box;
        }

        .color-item {
            margin-inline-end: calc(var(--global-margin) * 0.3);
            height:  calc(var(--global-margin) * 1.25);
            width:  calc(var(--global-margin) * 1.25);
            border: 1px solid transparent;
            border-radius: 50%;
            padding: 3px;
            cursor: pointer;
            transition: border-color 150ms;


            &.selected {
                border-color: var(--global-secondary-background);
            }

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                transition: box-shadow 150ms;
            }

            &:hover {
                .inner {
                    box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
                }
            }

            &.selected:hover {
                .inner {
                    box-shadow: none;
                }
            }

        }
    }

    .uk-form-large .color-list {
        .color-item {
            margin-inline-end: calc(var(--global-margin) * 0.5);
            height:  calc(var(--global-margin) * 1.75);
            width:  calc(var(--global-margin) * 1.75);
            border-width: 2px;
            border-radius: 50%;
            padding: 4px;

            &:hover {
                .inner {
                    box-shadow: 0 0 8px 0px rgba(0,0,0,0.3);
                }
            }

            // despite not being an override in itself, this is required
            &.selected:hover {
                .inner {
                    box-shadow: none;
                }
            }

        }
    }
