
    .main-content-wrapper {
        padding: 25px 40px;
    }

    .admin-view-toolbar {
        padding-top: calc(var(--global-margin) * 2);
        padding-left: calc(var(--global-margin) * 2);
        padding-right: calc(var(--global-margin) * 2);
    }

    .rtl .title-wrapper {
        margin-right: 0!important;
        margin-left: calc(var(--global-margin) * 2)!important;
    }
    .rtl .title-back-icon-wrap {
        margin-right: 0!important;
        margin-inline-end: calc(var(--global-margin) * 0.2)!important;
    }
    .rtl .title-back-icon {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);

    }



