

    // specific selector to override UIkit
    .color-select-wrapper.uk-form-large:not(textarea):not([multiple]):not([size]) {
        padding-left: 0;
        padding-right: 0;
    }

    .option-list {
        box-sizing: border-box;



        * {
            box-sizing: border-box;
        }

        .option-item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-inline-end: calc(var(--global-margin) * 0.6);
            height:  auto;
            width:  auto;
            border: 2px solid transparent;
            border-radius: 0;
            padding:calc(var(--global-margin) * 0.1)  calc(var(--global-margin) * 0.6);
            cursor: pointer;
            transition: border-color 150ms, box-shadow 150ms;
            border-color: var(--global-muted-color);


            &.selected {
                border-color: var(--global-primary-background);

                .inner {
                    color: var(--global-primary-background);
                }
            }

            .inner {
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 0;
                color: var(--global-muted-color);
                font-weight: 500;
                border-radius: 4px;


            }

            .selected-icon {
                position: absolute;
                display: flex;
                top: -50%;
                right: calc(var(--global-margin) * -0.5);
                background-color: var(--global-primary-background);
                color: var(--global-inverse-color);
                padding: 1px;
                border-radius: 50%;
                font-weight: bold;
                z-index:2;

            }
            &:hover {
                box-shadow: 0 0 8px 0px rgba(0,0,0,0.3) inset;
            }

            &.selected:hover {
                box-shadow: none;
            }

        }
    }


