
    .nav-container {
        height: 100%;
    }

    .side-nav-items {
        margin-top: 0!important;
        height: 100%;
        transition: border-color 200ms ease-in-out;

        &.root {
            border-top: 4px solid var(--global-secondary-background);
        }

        &.root.child-active {
            border-top: 4px solid var(--global-primary-background);
            box-shadow: 0 0 12px 1px rgba(0,0,0,0.3);
        }

        .sub-nav-item, .pseudo-sub-nav-item {
            min-width: 220px;
            margin-top: 0;
            margin-bottom: calc(var(--global-margin)* 0.8);

        }

        .sub-nav-item:first-of-type {
            margin-top: 20px;
        }

        .sub-nav-link {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            overflow: visible;
            color: var(--global-inverse-color);


            padding-top: calc(var(--global-margin) * 0.35);
            padding-bottom:  calc(var(--global-margin) * 0.35);
            padding-inline-start: calc(var(--global-margin) * 0.7);
            padding-inline-end: calc(var(--global-margin) * 1.5);
            transition: all 250ms ease;

            .item-icon {
                margin-inline-end: calc(var(--global-margin) * 0.7);
            }

            .item-text {
                text-transform: uppercase;
                font-size: .875rem;
                margin-inline-end: auto;
                margin-inline-start: 0;
                transition: all 220ms ease-out;
            }

            .children-indicator {
                position: absolute;
                inset-inline-end: calc(var(--global-margin) * 0.4);
                top: 50%;
                transform: translateY(-50%);
                transition: inset-inline-end 125ms ease-in;
            }

            &.active, &:hover {
                background-color: var(--global-primary-background);

                .item-text {
                    text-transform: uppercase;
                    font-size: .875rem;
                }

                .item-notification {
                    background-color: var(--global-inverse-color);
                    color: var(--global-primary-background)!important;
                }

                .children-indicator {
                    inset-inline-end: calc(var(--global-margin) * 0.25);
                }
            }
        }

        .sub-nav-item.link-primary .sub-nav-link {
            background-color: var(--global-primary-background);

            &.active, &:hover {
                background-color: var(--global-link-hover-color);
            }

        }

        .pseudo-sub-nav-item.sub-nav-item-title {
            position: relative;
            margin-bottom: calc(var(--global-margin) * 0);

            .sub-nav-link {
                background-color: var(--global-primary-background-dark);
                color: var(--global-inverse-color);
                font-weight: 600;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: not-allowed;

                padding-top: calc(var(--global-margin) * 0.4);
                padding-bottom: calc(var(--global-margin) * 0.4);

                .item-text {
                    margin: 0;
                }

                &:hover {
                    .item-text {

                        margin-inline-start: 0;
                        margin-inline-end: 0;
                    }
                }
            }
        }

        .pseudo-sub-nav-item.sub-nav-item-back {
            margin-bottom: calc(var(--global-margin) * 0.75);

            .sub-nav-link {

                background-color: var(--global-color);
                box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.4);

                .item-text{
                    margin-inline-start: inherit;
                    margin-inline-end: auto;
                }

                .item-icon {
                    position: relative;
                    inset-inline-start: 0;
                    transition: inset-inline-start 150ms ease;
                }
                &:hover {
                    background-color: var(--global-link-hover-color);

                    .item-icon {
                        inset-inline-start: calc(var(--global-margin) * -0.3);
                    }
                }
            }
        }


        .sub-indicator {
            position: relative;
            width: auto;
            padding: calc(var(--global-margin) * 0.35) calc(var(--global-margin) * 0.35);
            //background: var(--global-primary-background);
            //color: var(--global-secondary-background);
            color: var(--global-inverse-color);
            background-color: var(--global-color);
            box-shadow: 3px 3px 12px 0 rgba(0,0,0,0.4);

            .sub-indicator-icon {
                z-index: 1;
            }

            .click-catcher {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                z-index: 2;
                cursor: pointer;
            }
        }
    }

    .item-notification {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        inset-inline-start: calc(var(--global-margin) * 1.15);
        top: calc(var(--global-margin) * -0.17);
        height: 18px;
        width: 18px;
        min-width: 18px;
        flex: 0 0 18px;
        line-height: 18px;
        font-size: 12px;
        transition: background-color 250ms ease, color 250ms ease;

        &:hover {
            color: var(--global-primary-background);
        }
        .is-rtl & {
           // right: calc(var(--global-margin) * 1.15);
           // left: auto;
        }

    }


