
   // $sidebar-width : 220px;
    $sidebar-width : 50px;
    .sidebar {
        min-width: $sidebar-width;
        width: $sidebar-width;
        flex: $sidebar-width;

        @media (max-width: 767px) {
            position: fixed;
            inset-inline-start: 0;
            top: 60px;
            height: calc(100vh - 60px);
            z-index: 2;
        }
    }

    .main {
        flex: calc(100% - #{$sidebar-width});
        max-width: calc(100% - #{$sidebar-width});
        padding: calc(var(--global-margin) * 2);
        box-sizing: border-box;

        @media (max-width: 767px) {
            flex: 1 1 100%;
            padding-inline-start: 40px;
            padding-inline-end: 0;
            padding-top: 0;
            max-width: 100%;
            margin: 0;

        }
    }
