
    .text-separator {
        position: relative;
        height: calc(var(--global-margin) * 1.5);
        margin: calc(var(--global-margin) * 1.5);
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        .text, hr {
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        hr {
            position: relative;
            z-index: 1;
            margin: 0;
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            height: auto;
            width: auto;
            background: var(--global-muted-background);
            padding: calc(var(--global-margin) * 0.7);
            min-height: calc(var(--global-margin) * 3.85);
            border: calc(var(--global-margin) * 0.7) solid var(--global-inverse-color);

        }
    }
